<template>
  <div class="diffusion-optin mx-auto w-full max-w-[300px]">
    <form
      v-if="!formSent"
      class="mx-auto flex w-full flex-col items-center"
      @submit.prevent="onSubmit"
    >
      <p class="mb-6 text-center text-[16px] font-[500] leading-tight text-white">
        {{ $t("diffusion.optin.title") }}
      </p>

      <!-- Phone-number input. -->
      <div class="mb-4">
        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
        <input
          v-maska="'## ## ## ## ##'"
          name="phonenumber"
          type="tel"
          autocomplete="tel"
          placeholder="Ex : 06 12 76 99 24"
          :value="formattedPhoneNumber"
          @blur="phonenumber.handleChange($event)"
          @input="onInput(($event.target as HTMLInputElement).value)"
        />

        <p
          v-if="phonenumber.errorMessage.value"
          class="mt-2 text-left text-[12px] font-[500] leading-tight text-white"
        >
          {{ phonenumber.errorMessage.value }}
        </p>
      </div>

      <!-- Optin checkbox input. -->
      <div class="mx-auto mb-4 w-full max-w-[290px]">
        <!-- eslint-disable-next-line -->
        <input type="checkbox" name="optin" />

        <div class="flex w-full">
          <!-- eslint-disable-next-line -->
          <div class="checkbox" @click="optin.handleChange('optinised')">
            <svg
              v-if="optin.checked?.value"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fff"
              stroke-width="2"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
            </svg>
          </div>

          <!-- eslint-disable-next-line -->
          <label name="optin" for="optin" @click="optin.handleChange('optinised')" class="text-white font-[500] text-[13px] text-left">
            {{ $t("diffusion.optin.label") }}
          </label>
        </div>

        <p
          v-if="optin.errorMessage.value"
          class="mt-2 ml-[34px] text-left text-[12px] font-[500] leading-tight text-white"
        >
          {{ optin.errorMessage.value }}
        </p>
      </div>

      <button
        v-if="meta.valid"
        type="submit"
        class="flex h-[40px] max-w-[85px] items-center justify-between rounded-md border-none bg-primary px-4 text-[16px] font-[500] text-white disabled:bg-opacity-50"
        :disabled="isLoading"
      >
        Ok
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#fff"
          stroke-width="2"
          class="ml-3 h-[16px] w-[16px]"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
        </svg>
      </button>
    </form>

    <p v-else class="text-center text-[16px] font-[500] text-white">
      Votre numéro de mobile a bien été enregistré.
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { defineRule, useForm, useField, useFieldValue } from "vee-validate";
import { AsYouType, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { useApiOp } from "@team-uep/vue-api-op";

const api = useApiOp();

defineRule("phone-number", (value: string) => isValidPhoneNumber(value, "FR"));

const isLoading = ref(false);
const formSent = ref(false);

const schema = computed(() => ({
  phonenumber: `required|phone-number:${useFieldValue("phonenumber").value}`,
  optin: "",
}));

const { values, handleSubmit, meta, resetForm } = useForm<{ phonenumber: string; optin: string }>({
  validationSchema: schema,
});

const phonenumber = useField("phonenumber", undefined, { initialValue: "" });
const optin = useField("optin", undefined, {
  type: "checkbox",
  checkedValue: "optinised",
  initialValue: "",
});

const formattedPhoneNumber = computed(() =>
  new AsYouType("FR").input(values.phonenumber ? (values.phonenumber as string) : "")
);

const onInput = (value: string) => {
  const shouldValidate =
    formattedPhoneNumber.value.length > 0 ||
    phonenumber.errors.value.length > 0 ||
    (phonenumber.meta.dirty && phonenumber.meta.validated);

  phonenumber.handleChange(value, shouldValidate);
};

const onSubmit = handleSubmit((submittedValues) => {
  const parsedPhoneNumber = parsePhoneNumber(submittedValues.phonenumber, "FR");

  // eslint-disable-next-line camelcase
  const payload: { sMobile: string; oOptins?: { iOptin_4: number } } = {
    sMobile:
      (parsedPhoneNumber.number.substring(1) as string | undefined) || submittedValues.phonenumber,
  };

  if (submittedValues.optin) {
    payload.oOptins = { iOptin_4: 1 };
  }

  isLoading.value = true;

  api
    .post("/user/phonenumber", payload)
    .then(() => {
      resetForm();
      formSent.value = true;
    })
    .finally(() => {
      isLoading.value = false;
    });
});
</script>

<style lang="scss" scoped>
.diffusion-optin {
  input {
    width: 290px;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0.5rem 1.25rem 0.5rem 0.75rem;
    height: 50px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    background-color: transparent;
    outline: none !important;

    &::placeholder {
      color: rgba(255, 255, 255, 0.25);
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    width: 24px;
    margin-top: 4px;
    margin-right: 0.75rem;
    border: 1px solid #fff;
    border-radius: 4px;

    svg {
      height: 1rem;
      width: 1rem;
    }
  }
}
</style>
