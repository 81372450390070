<template>
  <div
    class="register-view relative min-h-screen bg-[#232120] lg:flex lg:items-start lg:justify-center"
  >
    <RegisterCover class="w-full" :is-in-form="isInForm" />

    <section
      class="flex flex-col pt-8 lg:min-h-screen lg:w-[580px] lg:min-w-[580px] lg:items-center lg:justify-center"
    >
      <template v-if="!isInForm">
        <h2
          class="text-center text-[40px] font-[600] leading-none text-white lg:mb-3 lg:text-[54px] lg:uppercase"
          data-cy="register-instruction"
          v-html="$t('register.baseline')"
        ></h2>

        <p
          class="text-center text-[16px] text-white lg:text-[24px]"
          v-html="$t('register.subBaseline')"
        ></p>

        <div class="mx-auto my-8 h-[4px] w-[50px] bg-[#FD7412] lg:my-12"></div>

        <div class="mx-auto text-center">
          <KrupsCta class="uppercase" @click="isInForm = true">
            {{ $t("register.cta") }}
          </KrupsCta>
        </div>
      </template>

      <template v-else>
        <p
          class="hidden text-center text-[16px] font-bold uppercase leading-tight text-white lg:block"
          v-html="$t('register.instruction')"
        ></p>

        <div class="mx-auto mt-8 mb-24 hidden h-[4px] w-[50px] bg-[#FD7412] lg:block"></div>

        <RegisterTypeForm
          v-if="!isDisplayingRelog"
          ref="registerTypeFormRef"
          class="mb-4"
          data-cy="register-type-form"
          :is-email-already-registered="isEmailAlreadyRegistered"
          :register-loading-state="registerLoadingState"
          @open-info-modal="displayInfoModal = true"
          @finished-animation="onApiResponse"
          @form-submitted="handleSubmit"
          @disable-is-email-already-registered="isEmailAlreadyRegistered = false"
        />

        <p class="mx-auto text-center text-[14px] text-white">
          {{ $t("register.form.optionalField") }}
        </p>
      </template>

      <Transition name="slide">
        <RegisterInfoModal v-if="displayInfoModal" @close="displayInfoModal = false" />
      </Transition>
    </section>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable camelcase */
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useApiOp } from "@team-uep/vue-api-op";

import type { APISessionCurrentResponse } from "@/types/api-extended.interfaces";
import type { RegisterTypes } from "@/components/register/useRegister";

import { useUserStore } from "@/store/user";
import { useTrackingStore } from "@/store/tracking";
import { updateStoreAfterSessionCurrent } from "@/helpers/store";
import RegisterTypeForm from "@/components/register/RegisterTypeForm.vue";
import RegisterCover from "@/components/register/RegisterCover.vue";
import RegisterInfoModal from "@/components/register/RegisterInfoModal.vue";

import { ValidStateModel } from "@team-uep/n-cta-loader";
import KrupsCta from "@/components/ui/KrupsCta.vue";

const router = useRouter();
const route = useRoute();
const api = useApiOp();
const userStore = useUserStore();
const trackingStore = useTrackingStore();

const isInForm = ref(false);
const isDisplayingRelog = ref(false);
const isEmailAlreadyRegistered = ref(false);
const displayInfoModal = ref(false);

const registerLoadingState = ref<ValidStateModel>("default");

const onApiResponse = (actualState: ValidStateModel) => {
  if (actualState === "success") {
    return router.push({ name: route.meta.nextPage as string });
  }
  if (actualState === "error") {
    registerLoadingState.value = "default";
  }
  return undefined;
};

type RegisterPayload = {
  iCivilId: number;
  sFirstName: string;
  sLastName: string;
  sEmail: string;
  sZipcode: string;
  oResponses: {
    sQuestion_101: string;
    dBirthDate_1?: string;
  };
  oOptins?:
    | {
        iOptin_2: number;
      }
    // eslint-disable-next-line @typescript-eslint/ban-types
    | {};
};

const handleSubmit = (values: { registerValues: RegisterTypes; isOptinDisplayed: boolean }) => {
  if (registerLoadingState.value !== "default") {
    return;
  }

  registerLoadingState.value = "progress";

  const leafletCode = values.registerValues.leaflet.replace(/\s/g, "");

  const formData: RegisterPayload = {
    iCivilId: Number(values.registerValues.civility),
    sFirstName: values.registerValues.firstname,
    sLastName: values.registerValues.lastname,
    sEmail: values.registerValues.email,
    sZipcode: String(values.registerValues.zipcode),
    oResponses: {
      sQuestion_101: leafletCode,
    },
    oOptins: {
      iOptin_2: values.registerValues.optin ? 1 : 0,
    },
  };

  if (values.registerValues.birthdate) {
    formData.oResponses.dBirthDate_1 = values.registerValues.birthdate;
  }

  if (!values.isOptinDisplayed || !values.registerValues.optin) {
    delete formData.oOptins;
  }

  api
    .register(formData)
    .then(() => api.getSessionCurrent())
    .then((response) => {
      updateStoreAfterSessionCurrent(
        response.data as APISessionCurrentResponse,
        userStore,
        trackingStore
      );

      registerLoadingState.value = "success";
    })
    .catch((err) => {
      registerLoadingState.value = "error";

      const errorStatus = err.response?.status;

      if (errorStatus === 409) {
        isEmailAlreadyRegistered.value = true;
      }
    });
};
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.25s ease-in-out;
  transform: translateY(0rem);
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(0.75rem);
}
</style>
