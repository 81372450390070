<template>
  <Form
    ref="form"
    v-slot="{ handleSubmit, errors, values }"
    class="mx-auto max-w-[320px] overflow-hidden px-[1px]"
    as="div"
    :validation-schema="schema"
    :initial-values="initialValues"
    @submit="null"
  >
    <n-typeform class="typeform" :current-slide="currentSlide">
      <template #slides>
        <!-- Leaflet code -->
        <n-typeform-slide :index="1">
          <template #default>
            <h3 class="typeform-input-index">1.</h3>

            <div class="relative mx-auto ml-[32px] max-w-[300px]">
              <n-mask
                id="input-leaflet"
                data-cy="input-leaflet"
                name="leaflet"
                type="text"
                class="leaflet-code"
                mask="# ### ### ### ###"
                :placeholder="$t('register.form.codePlaceholder')"
                :error-color="ui.colors.error"
                :design-type="ui.panoply.inputs.designType"
                :selected-background-color="ui.colors.primary"
                @input="updateFieldValidity('leaflet')"
                @keyup.enter="jumpSlide()"
              >
                {{ $t("register.form.codeLabel") }}
              </n-mask>

              <button type="button" class="icon-info" @click="emit('open-info-modal')">
                <img src="@/assets/icons/info-icon.svg" alt="Info" />
              </button>
            </div>

            <n-typeform-cta
              v-if="validity.leaflet"
              data-cy="cta-next"
              :background-color="ui.colors.primary"
              @click="jumpSlide()"
              >{{ $t("ok") }}
            </n-typeform-cta>
          </template>
        </n-typeform-slide>

        <!-- Civility -->
        <n-typeform-slide :index="2">
          <template #default>
            <h3 class="typeform-input-index">2.</h3>

            <div class="relative mx-auto ml-[32px] max-w-[300px]">
              <n-radios
                id="input-civility"
                data-cy="input-civility"
                name="civility"
                design-type="box"
                border-type="squared"
                :error-color="ui.colors.error"
                :selected-background-color="ui.colors.primary"
                selected-color="white"
                :border-color="ui.colors.primary"
                :values="{ [$t('register.form.ms')]: 2, [$t('register.form.mr')]: 1 }"
                @input="updateFieldValidity('civility', jumpSlide)"
              >
                {{ $t("register.form.genderLabel") }}
              </n-radios>
            </div>
          </template>
        </n-typeform-slide>

        <!-- First name -->
        <n-typeform-slide :index="3">
          <template #default>
            <h3 class="typeform-input-index">3.</h3>

            <div class="relative mx-auto ml-[32px] max-w-[300px]">
              <n-text
                id="input-firstname"
                data-cy="input-firstname"
                name="firstname"
                type="text"
                :placeholder="$t('register.form.firstnamePlaceholder')"
                :error-color="ui.colors.error"
                :design-type="ui.panoply.inputs.designType"
                :selected-background-color="ui.colors.primary"
                @input="updateFieldValidity('firstname')"
                @keyup.enter="jumpSlide()"
              >
                {{ $t("register.form.firstnameLabel") }}
              </n-text>
              <n-typeform-cta
                v-if="validity.firstname"
                data-cy="cta-next"
                :background-color="ui.colors.primary"
                @click="jumpSlide()"
                >{{ $t("ok") }}
              </n-typeform-cta>
            </div>
          </template>
        </n-typeform-slide>

        <!-- Last name -->
        <n-typeform-slide :index="4">
          <template #default>
            <h3 class="typeform-input-index">4.</h3>

            <div class="relative mx-auto ml-[32px] max-w-[300px]">
              <n-text
                id="input-lastname"
                data-cy="input-lastname"
                name="lastname"
                type="text"
                :placeholder="$t('register.form.lastnamePlaceholder')"
                :error-color="ui.colors.error"
                :design-type="ui.panoply.inputs.designType"
                :selected-background-color="ui.colors.primary"
                @input="updateFieldValidity('lastname')"
                @keyup.enter="jumpSlide()"
              >
                {{ $t("register.form.lastnameLabel") }}
              </n-text>
              <n-typeform-cta
                v-if="validity.lastname"
                data-cy="cta-next"
                :background-color="ui.colors.primary"
                @click="jumpSlide()"
              >
                {{ $t("ok") }}
              </n-typeform-cta>
            </div>
          </template>
        </n-typeform-slide>

        <!-- Email -->
        <n-typeform-slide :index="5">
          <template #default>
            <h3 class="typeform-input-index">5.</h3>

            <div class="relative mx-auto ml-[32px] max-w-[300px]">
              <n-text
                id="input-email"
                data-cy="input-email"
                name="email"
                type="email"
                :placeholder="$t('register.form.emailPlaceholder')"
                :error-color="ui.colors.error"
                :design-type="ui.panoply.inputs.designType"
                :selected-background-color="ui.colors.primary"
                @input="onEmailChanged(errors.email, values as RegisterTypes)"
                @keyup.enter="updateFieldValidity('email', jumpSlide)"
              >
                {{ $t("register.form.emailLabel") }}
              </n-text>

              <n-typeform-cta
                v-if="!errors.email && values.email.length > 4"
                data-cy="cta-next"
                :background-color="ui.colors.primary"
                @click="updateFieldValidity('email', jumpSlide)"
              >
                {{ $t("ok") }}
              </n-typeform-cta>
            </div>
          </template>
        </n-typeform-slide>

        <!-- Birthdate -->
        <n-typeform-slide :index="6">
          <template #default>
            <h3 class="typeform-input-index">6.</h3>

            <div class="relative mx-auto ml-[32px] max-w-[300px]">
              <n-mask
                id="input-birthdate"
                data-cy="input-birthday"
                name="birthdate"
                type="text"
                :placeholder="$t('register.form.birthdayPlaceholder')"
                mask="##/##/####"
                :error-color="ui.colors.error"
                :design-type="ui.panoply.inputs.designType"
                :selected-background-color="ui.colors.primary"
                @input="updateFieldValidity('birthdate')"
                @keyup.enter="jumpSlide()"
              >
                {{ $t("register.form.birthdayLabel") }}
              </n-mask>
              <n-typeform-cta
                v-if="validity.birthdate"
                data-cy="cta-next"
                :background-color="ui.colors.primary"
                @click="jumpSlide()"
              >
                {{ $t("ok") }}
              </n-typeform-cta>
            </div>
          </template>
        </n-typeform-slide>

        <!-- Zipcode -->
        <n-typeform-slide :index="7">
          <template #default>
            <h3 class="typeform-input-index">7.</h3>

            <div class="relative mx-auto ml-[32px] max-w-[300px]">
              <n-mask
                id="input-zipcode"
                data-cy="input-zipcode"
                name="zipcode"
                type="number"
                :placeholder="$t('register.form.zipcodePlaceholder')"
                mask="#####"
                :error-color="ui.colors.error"
                :design-type="ui.panoply.inputs.designType"
                :selected-background-color="ui.colors.primary"
                @input="updateFieldValidity('zipcode')"
                @keyup.enter="jumpSlide()"
              >
                {{ $t("register.form.zipcodeLabel") }}
              </n-mask>
              <n-typeform-cta
                v-if="validity.zipcode"
                data-cy="cta-next"
                :background-color="ui.colors.primary"
                @click="jumpSlide()"
              >
                {{ $t("ok") }}
              </n-typeform-cta>
            </div>
          </template>
        </n-typeform-slide>

        <!-- Optin + submit CTA -->
        <n-typeform-slide :index="8">
          <template #default>
            <n-checkbox
              id="input-terms"
              data-cy="input-terms"
              name="terms"
              :border-color="ui.colors.primary"
              :error-color="ui.colors.error"
              @input="updateFieldValidity('terms')"
            >
              {{ $t("register.form.terms") }}
            </n-checkbox>
            <n-checkbox
              id="input-optin"
              data-cy="input-optin"
              name="optin"
              :border-color="ui.colors.primary"
              :error-color="ui.colors.error"
            >
              <span v-html="$t('register.form.optinLabel')"></span>
            </n-checkbox>

            <span class="m-auto table">
              <NCtaLoader
                :background-color="{
                  default: ui.colors.primary,
                  hover: 'white',
                  error: ui.colors.error,
                  success: ui.colors.primary,
                }"
                :text-color="{ default: 'white', hover: ui.colors.primary }"
                :border-color="{ default: 'transparent', hover: 'transparent' }"
                :disabled="false"
                border-radius="24px"
                :state="registerLoadingState"
                data-cy="submit-register"
                @click="handleSubmit(handleSubmitForm)"
                @finished-animation="handleFinishedAnimation"
                @keyup.enter="jumpSlide()"
              >
                {{ $t("register.form.submit") }}
              </NCtaLoader>
            </span>
          </template>
        </n-typeform-slide>
      </template>

      <template #navigation>
        <n-typeform-navigation
          ref="nTypeFormRef"
          data-cy="typeform-complete"
          :current-slide="currentSlide"
          :required-slides="requiredSlides.length"
          :total-slides="totalSlidesCount"
          :completed-slides="completedSlidesCount"
          :next-enabled="validity[slides[currentSlide]]"
          :background-color="ui.colors.primary"
          @next="nextSlide"
          @prev="prevSlide"
        >
          {{ $t("register.typeform.complete") }}
        </n-typeform-navigation>
      </template>
    </n-typeform>

    <div class="flex items-center justify-center"></div>
  </Form>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch, computed, onMounted } from "vue";
import { Form, SubmissionHandler } from "vee-validate";
import { useApiOp } from "@team-uep/vue-api-op";
import { NCheckbox, NText, NMask, NRadios } from "@team-uep/n-inputs";
import { NTypeform, NTypeformSlide, NTypeformCta, NTypeformNavigation } from "@team-uep/n-typeform";
import { NCtaLoader, ValidStateModel } from "@team-uep/n-cta-loader";

import useUI from "@/composables/useUI";
import useRegister, { RegisterTypes, RegisterField } from "./useRegister";

const props = defineProps<{
  isEmailAlreadyRegistered: boolean;
  registerLoadingState: ValidStateModel;
}>();

const emit = defineEmits<{
  (e: "form-submitted", values: { registerValues: RegisterTypes; isOptinDisplayed: boolean }): void;
  (e: "disableIsEmailAlreadyRegistered", value: boolean): void;
  (e: "finishedAnimation", value: ValidStateModel): void;
  (e: "open-info-modal"): void;
}>();

const registerLoadingState = computed(() => props.registerLoadingState);

const { form, initialValues, schema } = useRegister(props);
const ui = useUI();
const api = useApiOp();

const currentSlide = ref(0);
const displayOptin = ref(false);

const validity = ref({
  [RegisterField.Leaflet]: false,
  [RegisterField.Civility]: false,
  [RegisterField.Firstname]: false,
  [RegisterField.Lastname]: false,
  [RegisterField.Email]: false,
  [RegisterField.Birthdate]: false,
  [RegisterField.Zipcode]: false,
  [RegisterField.Optin]: false,
  [RegisterField.Terms]: false,
} as { [key in RegisterField]: boolean });

/**
 * Slide name list
 */
const slides = [
  RegisterField.Leaflet,
  RegisterField.Civility,
  RegisterField.Firstname,
  RegisterField.Lastname,
  RegisterField.Email,
  RegisterField.Birthdate,
  RegisterField.Zipcode,
  RegisterField.Optin,
  RegisterField.Terms,
] as RegisterField[];

/**
 * Update a field validity with vee-validate
 */
const updateFieldValidity = async (name: RegisterField | string, callback?: () => void) => {
  if (!form.value) {
    return;
  }

  const result = await form.value.validateField(name as RegisterField);
  validity.value[name as RegisterField] = result.valid;

  if (callback) {
    callback();
  }
};

type GenericFormValues = {
  [x: string]: unknown;
};

/**
 * Send register data
 */
const handleSubmitForm: SubmissionHandler<GenericFormValues, unknown> = (
  values: GenericFormValues
) => {
  emit("form-submitted", {
    registerValues: values as RegisterTypes,
    isOptinDisplayed: displayOptin.value,
  });
};

const handleFinishedAnimation = (value: ValidStateModel) => {
  emit("finishedAnimation", value);
};
/**
 * Test slide validity by index
 */
const checkSlideAuth = (index: number) => {
  const next = Math.min(slides.length - 1, Math.max(0, index));
  for (let i = 0; i < next; i += 1) {
    const slideName = slides[i];
    if (!validity.value[slideName]) {
      return false;
    }
  }
  return true;
};

/**
 * Change slide by index
 */
const goSlide = (index: number) => {
  if (checkSlideAuth(index) && index <= slides.length) {
    currentSlide.value = index;

    // Index 5 is for birthdate. It's an optional field, we should be able to
    // skip it without entering any value.
    if (index === 5) {
      updateFieldValidity(RegisterField.Birthdate);
    }
  }
};

/**
 * Go to previous slide
 */
const prevSlide = () => {
  goSlide(currentSlide.value - 1);
};

/**
 * Go to next slide
 */
const nextSlide = () => {
  goSlide(currentSlide.value + 1);
};

/**
 * Go to next unvalid slide
 */
const jumpSlide = () => {
  const num = slides.findIndex((name) => validity.value[name] === false);
  goSlide(num < 0 ? currentSlide.value + 1 : num);
};

/**
 * Required slides count
 */
const requiredSlides = slides.filter((name) => name !== RegisterField.Optin);

/**
 * Total slides count
 */
const totalSlidesCount = slides.length + 1;

/**
 * Valid slides count for required slides
 */
const completedSlidesCount = computed(
  () => requiredSlides.map((name) => validity.value[name]).filter((valid) => valid).length
);

// Update email errors if email is already used
watch(
  () => props.isEmailAlreadyRegistered,
  (value, oldValue) => {
    if (value && !oldValue) {
      validity.value[RegisterField.Email] = false;
      goSlide(slides.indexOf(RegisterField.Email));
    }
  }
);

const isDedup = async (value: string) => {
  try {
    if (value.includes("@") && value.includes(".")) {
      const optin = (await api.dedupEmail(value)).data.data[0] as {
        optins: {
          // eslint-disable-next-line camelcase
          iOptinMarque_2: number;
        };
      };

      if (optin.optins.iOptinMarque_2 === 0 || optin.optins.iOptinMarque_2 === null) {
        displayOptin.value = true;
      } else if (optin.optins.iOptinMarque_2 === 1) {
        displayOptin.value = false;
      } else {
        displayOptin.value = false;
      }
    } else {
      displayOptin.value = false;
    }
  } catch {
    displayOptin.value = true;
  }
};

/**
 * Called if email modified
 */
const onEmailChanged = (hasError: string | undefined, values: RegisterTypes) => {
  isDedup(values.email);
  if (props.isEmailAlreadyRegistered) {
    emit("disableIsEmailAlreadyRegistered", false);
  }

  if (hasError) {
    updateFieldValidity(RegisterField.Email);
  }
};

// On component mounted, if user have pre-filled the form using URL query
// parameters, we need to force the validation of those specific fields.
onMounted(() => {
  if (initialValues.value.email) {
    updateFieldValidity(RegisterField.Email);
  }

  if (initialValues.value.firstname) {
    updateFieldValidity(RegisterField.Firstname);
  }

  if (initialValues.value.lastname) {
    updateFieldValidity(RegisterField.Lastname);
  }

  if (initialValues.value.civility) {
    updateFieldValidity(RegisterField.Civility);
  }
});
</script>

<style lang="css" scoped>
.typeform :deep(.valid-icon) {
  display: inline-block;
}

.typeform .icon-info {
  position: absolute;
  top: 0;
  right: 12px;
  margin-top: 56px;
}

.typeform .typeform-input-index {
  position: absolute;
  top: -1px;
  left: 12px;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fd7412;
}
</style>
